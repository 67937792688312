<template>
  <div>
    <NavBar></NavBar>
    <div class="container">
      <br>
      <h1>Gopher Products</h1>
      <Loader v-if="loading"></Loader>
      <div class="row" v-else>
        <div v-for="product in products" :key="product.id" class="col-6 col-md-3 d-flex justify-content-center mt-3">
          <router-link v-if="product.tutorialId === '' " :to="{ name: routeNames.PRODUCT_DETAIL, params : {productId: product.id, slug: product.slug}}">
            <ProductThumbnail
                :product="product"
            ></ProductThumbnail>
          </router-link>
          <!-- Special handling for tutorials-->
          <router-link v-else :to="{ name: routeNames.TUTORIAL,params : {tutorialId: product.tutorialId, slug: product.slug}}">
            <ProductThumbnail
                :product="product"
            ></ProductThumbnail>
          </router-link>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar";
import Webservice from "../webservice";
import ProductThumbnail from "@/components/ProductThumbnail";
import Consts from "@/consts";
import Loader from "@/components/Loader";
import Footer from "@/components/Footer";

export default {
  name: "Products",
  components: {Footer, Loader, ProductThumbnail, NavBar},
  metaInfo () {
    return {
      title: this.titleMeta,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.titleMeta },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.titleMeta},
        { property: 'og:description', content: this.descriptionMeta },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]
    }
  },
  data(){
    return {
      loading: false,
      products: null,
      routeNames: Consts.ROUTE_NAMES,
      titleMeta : "Cute Gopher Merchandising - Practical Go Lessons",
      descriptionMeta:"Gopher Merchandising to sell, only original design not branded, cute things in there"
    }
  },
  created() {
    this.loading = true
    Webservice.getProducts("").then((res)=> {
      this.products = res.data
      this.loading = false
    }).catch((err)=>{
      console.error(err)
      this.loading = false
    })

  }
}
</script>

<style scoped>

</style>