<template>
  <div class="card" style="width: 12rem; ">
    <img :src="product.images[0].url" class="card-img-top" :alt="product.images[0].alt">
    <div class="card-body">
      <h5 class="card-title">{{ product.name }}</h5>
      <p class="card-text">{{ product.totalPrice.display }}</p>
      <p>{{ product.shortDescription }}</p>
      <b-button variant="success" block>See</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductThumbnail",
  props: {
    product: Object,
  }
}
</script>

<style scoped>

</style>